module.exports = [{
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": [],
    "maxWidth": 672
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-108431118-1"
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
  options: {
    "plugins": [],
    "color": "#c62828"
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
  options: {
    "plugins": [],
    "name": "Gingerbox",
    "short_name": "Gingerbox",
    "description": "Gingerbox: A Sweet Tradition.",
    "start_url": "/gingerbox",
    "background_color": "#e0e0e0",
    "theme_color": "#c62828",
    "display": "minimal-ui",
    "icons": [{
      "src": "/logos/logo-192.png",
      "sizes": "192x192",
      "type": "image/png"
    }, {
      "src": "/logos/logo-512.png",
      "sizes": "512x512",
      "type": "image/png"
    }]
  }
}, {
  plugin: require('C:/Users/evans/Documents/Gingerbox/gatsby/gingerbox02/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];