// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": function componentNodeModulesGatsbyPluginOfflineAppShellJs() {
    return import("C:\\Users\\evans\\Documents\\Gingerbox\\gatsby\\gingerbox02\\node_modules\\gatsby-plugin-offline\\app-shell.js"
    /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */
    );
  },
  "component---src-templates-post-jsx": function componentSrcTemplatesPostJsx() {
    return import("C:\\Users\\evans\\Documents\\Gingerbox\\gatsby\\gingerbox02\\src\\templates\\post.jsx"
    /* webpackChunkName: "component---src-templates-post-jsx" */
    );
  },
  "component---src-templates-tag-jsx": function componentSrcTemplatesTagJsx() {
    return import("C:\\Users\\evans\\Documents\\Gingerbox\\gatsby\\gingerbox02\\src\\templates\\tag.jsx"
    /* webpackChunkName: "component---src-templates-tag-jsx" */
    );
  },
  "component---src-templates-category-jsx": function componentSrcTemplatesCategoryJsx() {
    return import("C:\\Users\\evans\\Documents\\Gingerbox\\gatsby\\gingerbox02\\src\\templates\\category.jsx"
    /* webpackChunkName: "component---src-templates-category-jsx" */
    );
  },
  "component---src-pages-about-jsx": function componentSrcPagesAboutJsx() {
    return import("C:\\Users\\evans\\Documents\\Gingerbox\\gatsby\\gingerbox02\\src\\pages\\about.jsx"
    /* webpackChunkName: "component---src-pages-about-jsx" */
    );
  },
  "component---src-pages-index-jsx": function componentSrcPagesIndexJsx() {
    return import("C:\\Users\\evans\\Documents\\Gingerbox\\gatsby\\gingerbox02\\src\\pages\\index.jsx"
    /* webpackChunkName: "component---src-pages-index-jsx" */
    );
  }
};